import React from 'react';
import { Col, Row } from 'antd';
import fontText from '../../../image/font/font.PNG';
import mobile from '../../../image/background/mobile.png';
import './LandingAboutPage.scss';
import LandingAboutPageDetails from './landingAboutPageDetails/LandingAboutPageDetails';
const LandingAboutPage = () => {
	return (
		<>
			<Row justify="center" className="aboutpage">
				<Col
					xxl={12}
					xl={18}
					lg={18}
					md={24}
					sm={24}
					xs={24}
					className="fontImageStyle"
				>
					<h1>About Offer Buzz</h1>
				</Col>
				<Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
					<Row justify="center">
						<Col
							xxl={10}
							xl={9}
							lg={10}
							md={22}
							sm={24}
							xs={24}
							className="aboutBackGroundimage"
						>
							<img
								src={mobile}
								alt="Girl in a jacket"
								className="customMovileImage"
							/>
						</Col>
						<Col xxl={12} xl={15} lg={14} md={22} sm={24} xs={23}>
							<LandingAboutPageDetails />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default LandingAboutPage;
