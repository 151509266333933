import React from 'react';
import { Col, Row, Card, Button } from 'antd';
import fontPic from '../../../image/font/OurBlogs.png';
import img1 from '../../../image/webImage/img1.png';
import img2 from '../../../image/webImage/img2.png';
import img3 from '../../../image/webImage/img3.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import './LandingPageOurBlogsPage.scss';
const LandingPageOurBlogsPage = () => {
	return (
		<>
			<section style={{ textAlign: 'center' }}>
				<Row justify="center">
					<Col
						xxl={18}
						xl={18}
						lg={18}
						md={24}
						sm={24}
						xs={24}
						className="blogsfotDiv"
					>
						<h1>Our Blogs</h1>
					</Col>
				</Row>
			</section>
			<section className="ourBlogsCardSection">
				<Row justify="space-around" gutter={[{ md: 0, lg: 0 }, 8]}>
					<Col xxl={7} xl={7} lg={7} md={22} sm={24} xs={22}>
						<Card
							cover={<img src={img1} className="cardImg" />}
							hoverable
							className="customcard"
						>
							<div className="promotionsCard">
								<p>
									Promotions{' '}
									<span style={{ paddingLeft: '30px', color: '#C2C2C2' }}>
										April 22,2022
									</span>
								</p>
								<h2>BDT 300 Discount on Pizza Guy!</h2>
								<div style={{ textAlign: 'end' }}>
									<a>Read More</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xxl={7} xl={7} lg={7} md={22} sm={24} xs={22}>
						<Card cover={<img src={img2} />} hoverable className="customcard">
							<div className="promotionsCard">
								<p>
									Promotions{' '}
									<span style={{ paddingLeft: '30px', color: '#C2C2C2' }}>
										April 22,2022
									</span>
								</p>
								<h2>BDT 300 Discount on Pizza Guy!</h2>
								<div style={{ textAlign: 'end' }}>
									<a>Read More</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xxl={7} xl={7} lg={7} md={22} sm={24} xs={22}>
						<Card cover={<img src={img3} />} hoverable className="customcard">
							<div className="promotionsCard">
								<p>
									Promotions{' '}
									<span style={{ paddingLeft: '30px', color: '#C2C2C2' }}>
										April 22,2022
									</span>
								</p>
								<h2>BDT 300 Discount on Pizza Guy!</h2>
								<div style={{ textAlign: 'end' }}>
									<a>Read More</a>
								</div>
							</div>
						</Card>
						<Row justify="end">
							<Col xxl={5} className="btnStyle">
								<Button>
									Go to Blog
									<ArrowRightOutlined style={{ color: '#cb0e00' }} />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>
		</>
	);
};

export default LandingPageOurBlogsPage;
