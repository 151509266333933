import React from 'react';
import FooterBody from './footerBody/FooterBody';
import FooterHeader from './footerHeader/FooterHeader';
import FooterFooter from './footerFooter/FooterFooter';
import './Footer.scss';
const Footer = () => {
	return (
		<>
			<section className="footerSection">
				<FooterHeader />
				<FooterBody />
				<FooterFooter />
			</section>
		</>
	);
};

export default Footer;
