import React from 'react';
import { Col, Row, Form, Button, Input, Checkbox, Image } from 'antd';
import orderOnlineResImage from '../../../image/webImage/pic1.png';
import orderOnlineResImage1 from '../../../image/webImage/pic2.png';
import './LandingOrderOnlinepage.scss';
const LandingOrderOnlinepage = () => {
	return (
		<>
			<section className="onlineOrder">
				<section style={{ textAlign: 'center' }}>
					<Row justify="center">
						<Col
							xxl={12}
							xl={16}
							lg={18}
							md={24}
							sm={24}
							xs={24}
							className="orderOnlinefontStyle"
						>
							<h1>Online Order</h1>
						</Col>

						<Col xxl={22} xl={22} lg={20} md={20} sm={20} xs={22}>
							<Form style={{}} layout="horizontal">
								<Form.Item
									name="captcha"
									noStyle
									rules={[
										{
											required: true,
											message: 'Please input the captcha you got!',
										},
									]}
								>
									<Input
										className="searchInputStyle"
										placeholder="Find your favorite 2000+ Restaurant"
									/>
									<Button className="searchbtnStyle">Search</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</section>
				<section className="checkboxDiv">
					<Row justify="space-around">
						<Col xxl={12} xl={12} lg={17} md={16} sm={22} xs={22}>
							<Checkbox className="checkBoxStyle">by Location</Checkbox>
							<Checkbox className="checkBoxStyle">by Offers</Checkbox>
							<Checkbox className="checkBoxStyle">by Most Review</Checkbox>
							<Checkbox className="checkBoxStyle">by Filter</Checkbox>
						</Col>
					</Row>
				</section>

				<section>
					<Row justify="center" className="orderOnlineImgDiv">
						<Col xxl={8} xl={11} lg={12} md={17} sm={22} xs={20}>
							<Image src={orderOnlineResImage1} className="orderOnlineImg" />
							<Image src={orderOnlineResImage} className="orderOnlineImg" />
							<Image src={orderOnlineResImage1} className="orderOnlineImg" />
							<Image src={orderOnlineResImage} className="orderOnlineImg" />
							<Image src={orderOnlineResImage1} className="orderOnlineImg" />
							<Image src={orderOnlineResImage} className="orderOnlineImg" />
						</Col>
					</Row>
				</section>
			</section>
		</>
	);
};

export default LandingOrderOnlinepage;
