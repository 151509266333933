import React from 'react';
import { Row, Col, Button } from 'antd';
import {
	CalendarOutlined,
	ArrowRightOutlined,
	MobileOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faWhatsapp,
	faInstagram,
	faYoutube,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import './LandingCoverPage.scss';

const LandingCoverPage = () => {
	return (
		<div className="landingPageDiv">
			<Row justify="space-around">
				<Col
					xxl={20}
					xl={21}
					lg={22}
					md={22}
					sm={24}
					xs={22}
					className="tetxDiv"
				>
					<h2>
						Best <span style={{ color: '#D22D00' }}>Platform</span>
						<br />
						to Find &<br />
						Advertise <br /> <span style={{ color: '#D22D00' }}>Offers</span>
					</h2>
					<div className="underLineDiv">
						<p>
							Don't Be Lost Because{' '}
							<span style={{ color: '#D22D00' }}> BUZZ</span> Is Here
						</p>
					</div>
					<Row gutter={[{ md: 8, lg: 8 }, 8]}>
						<Col span={24}>
							<Button icon={<CalendarOutlined />} className="registerBtn">
								Register Restaurant <ArrowRightOutlined className="arrow" />
							</Button>
						</Col>
						<Col span={24}>
							<Button icon={<MobileOutlined />} className="downloadAppBtn">
								Download the App <ArrowRightOutlined />
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row justify="end">
				<Col xxl={5} xl={7} lg={7} md={9} sm={24} xs={23} className="iconDiv">
					<Button>
						{' '}
						<FontAwesomeIcon
							icon={faFacebookF}
							className="iconFontSize"
							style={{ color: '#2554A2' }}
						/>
					</Button>

					<Button>
						{' '}
						<FontAwesomeIcon
							className="iconFontSize"
							icon={faWhatsapp}
							style={{
								color: '#00D729',
							}}
						/>
					</Button>
					<Button>
						{' '}
						<FontAwesomeIcon
							className="iconFontSize"
							icon={faInstagram}
							style={{ color: '#E21774' }}
						/>
					</Button>
					<Button>
						<FontAwesomeIcon
							className="iconFontSize"
							icon={faYoutube}
							style={{ color: '#FF1504' }}
						/>
					</Button>
					<Button>
						{' '}
						<FontAwesomeIcon
							className="iconFontSize"
							icon={faTwitter}
							style={{ color: '#00ACF3' }}
						/>
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default LandingCoverPage;
