import React from 'react';
import { Col, Row, Input } from 'antd';
import './FooterHeader.scss';
const FooterHeader = () => {
	const { Search } = Input;
	const onSearch = (value) => console.log(value);
	return (
		<section>
			<section>
				<Row justify="center">
					<Col xxl={21} xl={22} lg={22} md={22} sm={22} xs={23}>
						<Row justify="space-between" className="search-container">
							<Col xxl={11} xl={11} lg={11} md={23} sm={24} xs={23}>
								<h2>Join Our Newsletter Now</h2>
								<h4>Get E-mail updates about our latest offers</h4>
							</Col>
							<Col xxl={11} xl={11} lg={11} md={23} sm={24} xs={23}>
								<Search
									placeholder="your email address.."
									enterButton="SUBSCRIBE"
									size="large"
									onSearch={onSearch}
									className="search-field"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>
		</section>
	);
};

export default FooterHeader;
