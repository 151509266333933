import React from 'react';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './LandingAboutPageDetails.scss';

const LandingAboutPageDetails = () => {
	return (
		<section>
			<Row className="customMargin" justify="center">
				<Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					<div style={{ display: 'flex' }}>
						<div className="detailsIconDiv">
							<div className="circleDiv">
								{/* <EnvironmentOutlined  /> */}
								<FontAwesomeIcon
									icon={faMapMarkerAlt}
									className="detailsIcon"
								/>
							</div>
						</div>

						<div className="aboutHeader">
							{' '}
							<p> Location</p>
						</div>
					</div>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					<div style={{ display: 'flex' }}>
						<div className="detailsIconDiv">
							<div className="circleDiv">
								<FontAwesomeIcon
									icon={faMapMarkerAlt}
									className="detailsIcon"
								/>
							</div>
						</div>

						<div className="aboutHeader">
							{' '}
							<p> Vendors</p>
						</div>
					</div>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					<div style={{ display: 'flex' }}>
						<div className="detailsIconDiv">
							<div className="circleDiv">
								<FontAwesomeIcon
									icon={faMapMarkerAlt}
									className="detailsIcon"
								/>
							</div>
						</div>

						<div className="aboutHeader">
							{' '}
							<p> Consumers</p>
						</div>
					</div>
				</Col>
				<Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={3}>
					<div style={{ display: 'flex' }}>
						<div className="detailsIconDiv">
							<div className="circleDiv">
								<FontAwesomeIcon
									icon={faMapMarkerAlt}
									className="detailsIcon"
								/>
							</div>
						</div>

						<div className="aboutHeader">
							<p> B2B</p>
						</div>
					</div>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					<div style={{ display: 'flex' }}>
						<div className="detailsIconDiv">
							<div className="circleDiv">
								<FontAwesomeIcon
									icon={faMapMarkerAlt}
									className="detailsIcon"
								/>
							</div>
						</div>

						<div className="aboutHeader">
							{' '}
							<p> Food Delivery</p>
						</div>
					</div>
				</Col>
				{/* <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					{' '}
					<p className="aboutHeader">
						{' '}
						<EnvironmentOutlined className="detailsIcon" />
						Vendors
					</p>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					{' '}
					<p className="aboutHeader">
						{' '}
						<EnvironmentOutlined className="detailsIcon" />
						Consumers
					</p>
				</Col>
				<Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={3}>
					{' '}
					<p className="aboutHeader">
						{' '}
						<EnvironmentOutlined className="detailsIcon" />
						B2B
					</p>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
					{' '}
					<p className="aboutHeader">
						<EnvironmentOutlined className="detailsIcon" />
						Food Delivery
					</p>
				</Col> */}
			</Row>
			<Row justify="space-between">
				<Col
					xxl={11}
					xl={11}
					lg={11}
					md={22}
					sm={24}
					xs={24}
					className="infoDetailsDiv"
				>
					<h2 className="infoTextHeader">
						Get <span style={{ color: '#cb0e00' }}>Notified</span>
					</h2>
					<p className="infoTextBody">
						Magna amet vero eirmod sit sea sed nonumy, et duo lorem et diam no
						erat elitr et nonumy, dolor no dolores voluptua nonumy. Sea ea stet
						lorem duo diam sea.{' '}
					</p>
				</Col>
				<Col
					xxl={11}
					xl={11}
					lg={11}
					md={22}
					sm={24}
					xs={24}
					className="infoDetailsDiv"
				>
					<h2 className="infoTextHeader">
						Decided <span style={{ color: '#cb0e00' }}>Where</span> to Visit
					</h2>
					<p className="infoTextBody">
						Magna amet vero eirmod sit sea sed nonumy, et duo lorem et diam no
						erat elitr et nonumy, dolor no dolores voluptua nonumy. Sea ea stet
						lorem duo diam sea.{' '}
					</p>
				</Col>
				<Col
					xxl={11}
					xl={11}
					lg={11}
					md={22}
					sm={24}
					xs={24}
					className="infoDetailsDiv"
				>
					{' '}
					<h2 className="infoTextHeader">
						Nearest <span style={{ color: '#cb0e00' }}>Friends</span>{' '}
					</h2>
					<p className="infoTextBody">
						Magna amet vero eirmod sit sea sed nonumy, et duo lorem et diam no
						erat elitr et nonumy, dolor no dolores voluptua nonumy. Sea ea stet
						lorem duo diam sea.{' '}
					</p>
				</Col>
				<Col
					xxl={11}
					xl={11}
					lg={11}
					md={22}
					sm={24}
					xs={24}
					className="infoDetailsDiv"
				>
					<h2 className="infoTextHeader">
						Decided <span style={{ color: '#cb0e00' }}>Where</span> to Visit
					</h2>
					<p className="infoTextBody">
						Magna amet vero eirmod sit sea sed nonumy, et duo lorem et diam no
						erat elitr et nonumy, dolor no dolores voluptua nonumy. Sea ea stet
						lorem duo diam sea.{' '}
					</p>
				</Col>
			</Row>
		</section>
	);
};

export default LandingAboutPageDetails;
