import React from 'react';
import { Col, Row } from 'antd';
import footerimg from '../../../image/coverImage/footer.png';
import footerimg1 from '../../../image/navImage/Asset 2.png';
import './FooterFooter.scss';
const FooterFooter = () => {
	const year = new Date().getFullYear();

	return (
		<>
			<Row justify="center">
				<Col xxl={21} xl={22} lg={22} md={22} sm={18} xs={16}>
					<Row justify="space-between">
						<Col
							xxl={12}
							xl={14}
							lg={16}
							md={23}
							sm={24}
							xs={24}
							className="footerCol"
						>
							<h3>
								<span style={{ color: '#f6b301' }}>
									Offer Buzz<span>&#169;</span>
								</span>
								{year} - A Concern of & Developed by eCarnivalBD Limited
							</h3>
						</Col>
						<Col
							xxl={6}
							xl={11}
							lg={11}
							md={16}
							sm={24}
							xs={23}
							className="footerImg"
						>
							<img src={footerimg} />
						</Col>
						<Col
							xxl={3}
							xl={5}
							lg={6}
							md={5}
							sm={24}
							xs={23}
							// className="footerImg"
						>
							<div className="footerBackground">
								<img src={footerimg1} />
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default FooterFooter;
