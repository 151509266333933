import React from 'react';
import { Col, Row, Card, Carousel, Button } from 'antd';
import banner from '../../../image/coverImage/banner.png';
import banner1 from '../../../image/coverImage/bannerjpgjkl.png';
import fontPic2 from '../../../image/coverImage/Group 283.png';
import Slider from 'react-slick';
import { ArrowRightOutlined, MobileOutlined } from '@ant-design/icons';
import './LandingPageUpCommingEventsPage.scss';

const LandingPageUpCommingEventsPage = () => {
	const settings = {
		dots: true,

		speed: 850,
		fade: true,
		autoplaySpeed: 4500,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<>
			<section className="upCommingEvents">
				<section style={{ textAlign: 'center' }}>
					<Row justify="center">
						<Col
							xxl={18}
							xl={18}
							lg={18}
							md={24}
							sm={24}
							xs={24}
							className="eventnfotDiv"
						>
							<h1>Upcomming Events</h1>
						</Col>
					</Row>
				</section>

				<section className="upCommingEventSliderSection">
					<Slider {...settings} className="customSlider">
						<div>
							<Row justify="center" gutter={[{ md: 8, lg: 8 }, 8]}>
								<Col xxl={14} xl={16} lg={16} md={24} sm={24} xs={23}>
									<img
										src={banner}
										alt="Girl in a jacket"
										className="bannarImg"
									/>
								</Col>
								<Col xxl={7} xl={7} lg={7} md={23} sm={24} xs={22}>
									<Card className="leftCard">
										<div>
											<h1>Offer Zest 2021</h1>

											<div className="topbody">
												<h2>
													<span style={{ color: '#D22E00' }}>Venue : </span>
													Baily Road
												</h2>
												<h2>
													<span style={{ color: '#D22E00' }}>Date : </span>
													22-24 September
												</h2>
												<h2>
													<span style={{ color: '#D22E00' }}>
														Event Page :{' '}
													</span>
													Facebook
												</h2>
											</div>

											<div className="middleBody">
												<h2>
													<span style={{ color: '#D22E00' }}>
														Organizer/s :{' '}
													</span>
													eCarnivalBD Limited
												</h2>
												<h2>
													<span style={{ color: '#D22E00' }}>
														In-Association with :
													</span>
													OfferBuzz
												</h2>
											</div>

											<div className="footerBody">
												<Button icon={<MobileOutlined />} className="learnBtn">
													Learn More <ArrowRightOutlined />
												</Button>
												<Button
													icon={<MobileOutlined />}
													className="registerBtn2"
												>
													Register <ArrowRightOutlined />
												</Button>
											</div>
										</div>
									</Card>
								</Col>
							</Row>
						</div>
						<div>
							<Row justify="center" gutter={[{ md: 8, lg: 8 }, 8]}>
								<Col xxl={14} xl={16} lg={16} md={24} sm={24} xs={23}>
									<img
										src={banner}
										alt="Girl in a jacket"
										className="bannarImg"
									/>
								</Col>
								<Col xxl={7} xl={7} lg={7} md={23} sm={24} xs={22}>
									<Card className="leftCard">
										<div>
											<h1>Offer Zest 2022</h1>

											<div className="topbody">
												<h2>
													<span style={{ color: '#D22E00' }}>Venue : </span>
													Baily Road
												</h2>
												<h2>
													<span style={{ color: '#D22E00' }}>Date : </span>
													22-24 September
												</h2>
												<h2>
													<span style={{ color: '#D22E00' }}>
														Event Page :{' '}
													</span>
													Facebook
												</h2>
											</div>

											<div className="middleBody">
												<h2>
													<span style={{ color: '#D22E00' }}>
														Organizer/s :{' '}
													</span>
													eCarnivalBD Limited
												</h2>
												<h2>
													<span style={{ color: '#D22E00' }}>
														In-Association with :
													</span>
													OfferBuzz
												</h2>
											</div>

											<div className="footerBody">
												<Button icon={<MobileOutlined />} className="learnBtn">
													Learn More <ArrowRightOutlined />
												</Button>
												<Button
													icon={<MobileOutlined />}
													className="registerBtn2"
												>
													Register <ArrowRightOutlined />
												</Button>
											</div>
										</div>
									</Card>
								</Col>
							</Row>
						</div>
					</Slider>
				</section>
				<img src={fontPic2} className="footerImgdiv" />
			</section>
		</>
	);
};

export default LandingPageUpCommingEventsPage;
