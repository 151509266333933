import React from 'react';
import { Row, Col, Button } from 'antd';
import { UserOutlined, GlobalOutlined } from '@ant-design/icons';
import './Navigation.scss';
import logo from '../../../image/navImage/Asset 2.png';
import logo1 from '../../../image/navImage/asset3.png';
const Navigation = () => {
	return (
		<>
			<div className="headerStyle">
				<Row justify="space-around">
					<Col xxl={8} xl={5} lg={4} md={4} sm={4} xs={6}>
						{/* <div className="backgroundImg" style={{ textAlign: 'center' }}>
							<img src={logo} alt="peng ting" className="obLogo" />
						</div> */}
						<div className="obLogo">
							<img src={logo1} alt="" />
						</div>
					</Col>
					<Col xxl={12} xl={14} lg={11} md={11} sm={11} xs={18}>
						<Row
							justify="end"
							className="customAlign"
							align="middle"
							style={{ height: '70px' }}
						>
							<Col
								xxl={5}
								xl={5}
								lg={11}
								md={11}
								sm={11}
								xs={10}
								className="navTextCol"
							>
								<p className="navText">VLOGS & STORIES</p>
							</Col>

							<Col xxl={5} xl={6} lg={11} md={11} sm={11} xs={14}>
								<Button className="buttonStyle">
									<UserOutlined /> <b>LOGIN</b>
								</Button>
								<GlobalOutlined className="globalIcon" />
								<p
									className="navText"
									style={{
										paddingLeft: '8px',
									}}
								>
									ENG
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Navigation;
