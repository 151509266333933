import './App.css';
import Layout from './hoc/layout/Layout';

function App() {
	return (
		<>
			<Layout></Layout>
		</>
	);
}

export default App;
