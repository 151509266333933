import React from 'react';
import Navigation from '../../components/navigation/navigation/Navigation';
import LandingPage from '../../containers/landingPage/LandingPage';
import Footer from '../../components/footer/Footer';
const Layout = (props) => {
	return (
		<>
			<Navigation />
			<LandingPage />
			<Footer />
		</>
	);
};

export default Layout;
