import React from 'react';
import LandingAboutPage from './landingAboutPage/LandingAboutPage';
import LandingCoverPage from './landingCoverPage/LandingCoverPage';
import LandingOrderOnlinepage from './landingOrderOnlinepage/LandingOrderOnlinepage';
import LandingPageHowToUsePage from './landingPageHowToUsePage/LandingPageHowToUsePage';
import LandingPageOurBlogsPage from './landingPageOurBlogsPage/LandingPageOurBlogsPage';
import LandingPageUpCommingEventsPage from './landingPageUpCommingEventsPage/LandingPageUpCommingEventsPage';
import './LandingPage.scss';
const LandingPage = () => {
	return (
		<>
			<LandingCoverPage />
			<LandingAboutPage />
			<LandingOrderOnlinepage />
			<LandingPageHowToUsePage />
			<LandingPageUpCommingEventsPage />
			<LandingPageOurBlogsPage />
		</>
	);
};

export default LandingPage;
