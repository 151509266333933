import React, { useState } from 'react';
import { Col, Row, Card } from 'antd';
import Slider from 'react-slick';
import img4 from '../../../image/coverImage/Group 207.png';
import './LandingPageHowToUsePage.scss';

const LandingPageHowToUsePage = () => {
	const { Meta } = Card;

	const contentStyle = {
		textAlign: 'center',
	};
	const settings = {
		dots: true,

		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	return (
		<section className="howtousecover">
			<section style={{ textAlign: 'center' }}>
				<Row justify="center">
					<Col
						xxl={12}
						xl={18}
						lg={18}
						md={24}
						sm={24}
						xs={24}
						className="usefontDiv"
					>
						<h1>How to use ?</h1>
					</Col>
				</Row>
			</section>
			<section className="sliderSection">
				<Row justify="center">
					<Col
						xxl={5}
						xl={7}
						lg={7}
						md={20}
						sm={20}
						xs={20}
						className="sliderDiv "
					>
						<Slider {...settings}>
							<div>
								<Card
									className="card"
									cover={
										<iframe
											width="560"
											height="315"
											src="https://www.youtube.com/embed/jaHd1Fm5zl0"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									}
								>
									<h1 className="useFontHeader" style={contentStyle}>
										Busniess Owners
									</h1>
									<h3 className="useFontBody" style={contentStyle}>
										{' '}
										Lorem amet invidunt voluptua gubergren diam lorem sanctus
										lorem. Sanctus dolores lorem diam sadipscing sanctus diam
										kasd. Diam sea justo.
									</h3>
								</Card>
							</div>
							<div>
								<Card
									className="card"
									cover={
										<iframe
											src="https://www.youtube.com/embed/foD42-73wdI"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									}
								>
									<h1 className="useFontHeader" style={contentStyle}>
										Busniess Owners
									</h1>
									<h3 className="useFontBody" style={contentStyle}>
										{' '}
										Lorem amet invidunt voluptua gubergren diam lorem sanctus
										lorem. Sanctus dolores lorem diam sadipscing sanctus diam
										kasd. Diam sea justo.
									</h3>
								</Card>
							</div>
						</Slider>
					</Col>
					<Col
						xxl={5}
						xl={7}
						lg={7}
						md={20}
						sm={20}
						xs={20}
						className="sliderDiv middleDiv"
					>
						<Slider {...settings}>
							<div>
								<Card
									className="card"
									cover={
										<iframe
											src="https://www.youtube.com/embed/foD42-73wdI"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									}
								>
									<h1 className="useFontHeader" style={contentStyle}>
										Busniess Owners
									</h1>
									<h3 className="useFontBody" style={contentStyle}>
										{' '}
										Lorem amet invidunt voluptua gubergren diam lorem sanctus
										lorem. Sanctus dolores lorem diam sadipscing sanctus diam
										kasd. Diam sea justo.
									</h3>
								</Card>
							</div>
							<div>
								<Card
									className="card"
									cover={
										<iframe
											src="https://www.youtube.com/embed/foD42-73wdI"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									}
								>
									<h1 className="useFontHeader" style={contentStyle}>
										Busniess Owners
									</h1>
									<h3 className="useFontBody" style={contentStyle}>
										{' '}
										Lorem amet invidunt voluptua gubergren diam lorem sanctus
										lorem. Sanctus dolores lorem diam sadipscing sanctus diam
										kasd. Diam sea justo.
									</h3>
								</Card>
							</div>
						</Slider>
					</Col>
					<Col
						xxl={5}
						xl={7}
						lg={7}
						md={20}
						sm={22}
						xs={20}
						className="sliderDiv "
					>
						<Slider {...settings}>
							<div>
								<Card
									className="card"
									cover={
										<iframe
											width="560"
											height="315"
											src="https://www.youtube.com/embed/3FU435v2aZs"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									}
								>
									<h1 className="useFontHeader" style={contentStyle}>
										Busniess Owners
									</h1>
									<h3 className="useFontBody" style={contentStyle}>
										{' '}
										Lorem amet invidunt voluptua gubergren diam lorem sanctus
										lorem. Sanctus dolores lorem diam sadipscing sanctus diam
										kasd. Diam sea justo.
									</h3>
								</Card>
							</div>
							<div>
								<Card
									className="card"
									cover={
										<iframe
											src="https://www.youtube.com/embed/foD42-73wdI"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									}
								>
									<h1 className="useFontHeader" style={contentStyle}>
										Busniess Owners
									</h1>
									<h3 className="useFontBody" style={contentStyle}>
										{' '}
										Lorem amet invidunt voluptua gubergren diam lorem sanctus
										lorem. Sanctus dolores lorem diam sadipscing sanctus diam
										kasd. Diam sea justo.
									</h3>
								</Card>
							</div>
						</Slider>
					</Col>
				</Row>
			</section>

			<img src={img4} className="rightBottomImg" />
		</section>
	);
};

export default LandingPageHowToUsePage;
