import React from 'react';
import { Col, Row, Button } from 'antd';
import img1 from '../../../image/coverImage/Group 315.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faWhatsapp,
	faInstagram,
	faYoutube,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import './FooterBody.scss';
const FooterBody = () => {
	return (
		<>
			<Row justify="center">
				<Col xxl={21} xl={22} lg={22} md={22} sm={22} xs={18}>
					{' '}
					<Row justify="space-between">
						<Col
							xxl={7}
							xl={11}
							lg={11}
							md={23}
							sm={24}
							xs={23}
							className="contactCol"
						>
							<h2>Contact Us</h2>
							<Row className="contactColRow">
								<Col
									xxl={2}
									xl={2}
									lg={2}
									md={2}
									sm={2}
									xs={4}
									style={{ textAlign: 'center' }}
								>
									<img src={img1} />
								</Col>
								<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={18}>
									<p>+(88) 0967 8800 405</p>
								</Col>
							</Row>
							<h3>
								Address:225/B Malibag Chowdhurypara Dhaka 1219 <br />
								Email: Info@offerbuzz.com
							</h3>
							<Button className="footerBodybtn" shape="circle">
								{' '}
								<FontAwesomeIcon
									icon={faFacebookF}
									style={{ color: '#2554A2' }}
									className="footerIcon"
								/>
							</Button>
							<Button className="footerBodybtn" shape="circle">
								{' '}
								<FontAwesomeIcon
									icon={faWhatsapp}
									style={{ color: '#00D729' }}
									className="footerIcon"
								/>
							</Button>
							<Button className="footerBodybtn" shape="circle">
								{' '}
								<FontAwesomeIcon
									icon={faInstagram}
									style={{ color: '#E21774' }}
									className="footerIcon"
								/>
							</Button>
							<Button className="footerBodybtn" shape="circle">
								<FontAwesomeIcon
									icon={faYoutube}
									style={{ color: '#FF1504' }}
									className="footerIcon"
								/>
							</Button>
							<Button className="footerBodybtn" shape="circle">
								{' '}
								<FontAwesomeIcon
									icon={faTwitter}
									style={{ color: '#00ACF3' }}
									className="footerIcon"
								/>
							</Button>
						</Col>
						<Col
							xxl={7}
							xl={8}
							lg={8}
							md={12}
							sm={24}
							xs={23}
							className="infoColstyle"
						>
							<h2>Information</h2>
							<div className="infoBody">
								<h3>About Us</h3>
								<h3>Contact Us</h3>
								<h3>Frequently Asked Question</h3>
								<h3>Privacy Policy</h3>
								<h3>Request Price Chart</h3>
							</div>
						</Col>
						<Col
							xxl={7}
							xl={4}
							lg={4}
							md={10}
							sm={24}
							xs={19}
							className="btnCol"
						>
							<div style={{ textAlign: 'end' }}>
								<Button>Business Sign Up</Button>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default FooterBody;
